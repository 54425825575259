import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateFormatPipe as DatePipe} from '../pipes/dateformat.pipe';
import {FeatureService} from '../../features/clienti/subfeatures/trattamento/feature.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../core/store/store.module';
import {
  getCategorieMantenimento,
  getCategorieTrattamento,
  getTipologieTrattamento
} from '../../core/store/settings/selectors';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CicloTrattamento} from '../../models/CicloTrattamento';

@Component({
  providers: [DatePipe],
  styles: [`
  `],
  template: `
    <div class="modal-inner" [formGroup]="form">
      <div class="modal-header">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <h4 class="modal-title"><strong>{{ !trattamento ? 'Nuovo' : 'Modifica' }} ciclo trattamento</strong></h4>
        </div>
      </div>
      <div class="modal-body">

        <div *ngIf="showAlert" class="alert alert-warning">
          Pianificando un nuovo {{this.categoria}} andrai ad archiviare quello attuale, che sarà consultabile nel menu
          "Archivio trattamenti"
        </div>

        <app-laravel-errors [errors]="errors"></app-laravel-errors>

        <h5 class="mb-4"><strong>{{ categoria === 'trattamento' ? 'Intensità' : 'Frequenza' }}</strong></h5>
        <select formControlName="categoria_id" class="form-control" name="trattamento"
                [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.categoria_id }">
          <option [ngValue]="null">Seleziona</option>
          <option *ngFor="let item of categorieTrattamento" value="{{ item.id }}">{{item.nome}}</option>
        </select>
        <span *ngIf="errors && errors.errors && errors.errors.categoria_id" class="invalid-feedback"
              role="alert">{{ errors && errors.errors && errors.errors.categoria_id[0] }}</span>

        <div class="form-group row mt-5" *ngIf="isCustom && categoria === 'trattamento' && !trattamento">
          <label class="col-form-label">N° appuntamenti</label>
          <div class="control-input">
            <input class="form-control" type="number" formControlName="num_appuntamenti">
          </div>
        </div>

        <div class="mt-5" *ngIf="isCustom && categoria === 'trattamento' && trattamento">
            <div>Puoi modificare il numero di appuntamenti cliccando su "Aggiungi appuntamento", oppure cliccando sul pulsante di eliminazione, nella schermata di elenco.</div>
        </div>

        <ng-container *ngIf="this.trattamento || this.selectedTrattamento">

          <hr class="separator">

          <div *ngIf="!trattamento">
            <h5 class="mb-4"><strong>Tipologia</strong></h5>
            <select formControlName="tipologia_id" class="form-control"
                    [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.tipologia_id }">
              <option [ngValue]="null">Seleziona</option>
              <option *ngFor="let item of tipologieTrattamento" value="{{ item.id }}">{{item.name}}</option>
            </select>
            <p class="mt-1"><i>Verrà inizialmente applicato su tutti gli appuntamenti.</i></p>
            <hr class="separator">
          </div>

          <h5 class="mt-4"><strong>Prodotti consigliati a domicilio</strong></h5>

          <app-products-selector filtro="ordine" [selectedProducts]="formProdottiArray.value"
                                 (onAdd)="handleAddProdotto($event)"
                                 (onRemove)="handleRemoveProdotto($event)" (onUpdateNote)="handleUpdateNote($event)" [note]="true"></app-products-selector>

          <div *ngIf="categoria === 'trattamento' && isCustom" formArrayName="disponibilita" class="mb-4">
            <hr class="separator">
            <h5 class="mt-4"><strong>Elenco fasi</strong></h5>
            <p>Avendo definito la tipologia personalizzata, puoi decidere le fasi del ciclo {{ categoria }}</p>
            <ng-container *ngFor="let _ of fromDisponibilitaArray.controls; index as i">
              <ng-container [formGroupName]="i">
                <div class="d-flex">
                  <input *ngIf="isCustom && categoria !== 'mantenimento'" formControlName="giorno" autocomplete="off"
                         class="form-control mb-3" placeholder="Inserisci la fase">
                  <div class="ml-1">
                    <button (click)="handleRemovePeriodicita(i)" *ngIf="i < fromDisponibilitaArray.controls.length - 1"
                            class="btn btn-sm btn-danger"><span class="flaticon2-trash"></span></button>
                  </div>
                </div>
                <button (click)="handleAddPeriodicita()"
                        *ngIf="fromDisponibilitaArray.controls.length === 1 || i === fromDisponibilitaArray.controls.length - 1"
                        class="btn btn-sm btn-default">Aggiungi fase
                </button>
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="!isCustom || (fromDisponibilitaArray.value.length && fromDisponibilitaArray.value[0].giorno)" formArrayName="disponibilita">
            <hr class="separator">
            <h5 class="mb-4"><strong>Fasi e disponibilità cliente</strong></h5>
            <p>Per programmare tutti gli appuntamenti è utile indicare quando il cliente ha disponibilità. Inseriscila
              per ogni fase del ciclo {{ categoria}}</p>
            <ng-container *ngFor="let _ of fromDisponibilitaArray.controls; index as i">
              <ng-container [formGroupName]="i">
                <ng-container *ngIf="fromDisponibilitaArray.value[i].giorno.trim() !== ''">
                <div class="pt-2 mb-2">
                  <ng-container><strong>Fase {{ i + 1}}</strong> -</ng-container> {{fromDisponibilitaArray.value[i].giorno }}:
                </div>
                <textarea formControlName="orario" autocomplete="off" class="form-control mb-3"
                          placeholder="Inserisci la disponibilità del cliente"></textarea>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>

          <hr class="separator mt-4">

          <h5 class="mb-4"><strong>Note</strong></h5>
          <textarea formControlName="note" class="form-control"></textarea>

        </ng-container>

      </div>


      <div class="modal-footer">
        <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
        <button class="btn btn-primary" (click)="handleSubmit()">Salva</button>
      </div>
    </div>

  `
})

export class CicloTrattamentoModalComponent implements OnInit {

  public trattamento: CicloTrattamento = null;
  public clienteId: number;
  public categoria: 'trattamento' | 'mantenimento' = 'trattamento';
  public showAlert = false;

  form: FormGroup;
  fromDisponibilitaArray: FormArray;
  formProdottiArray: FormArray;

  errors = null;

  selectedTrattamento = null;
  categorieTrattamento = [];
  tipologieTrattamento = [];
  disponibilitaList = [];
  isCustom = false;

  productDescriptions = {};

  constructor(
    public modal: NgbActiveModal,
    private service: FeatureService,
    private fb: FormBuilder,
    private store: Store<AppState>
  ) {

    // this.store.dispatch(loadSettings());

    this.form = fb.group({
      num_appuntamenti: fb.control(null),
      num_mesi: fb.control(4),
      note: fb.control(null),
      categoria_id: fb.control(null, [Validators.required]),
      tipologia_id: fb.control(null, [Validators.required]),
      disponibilita: new FormArray([]),
      prodotti: new FormArray([])
    });

    this.fromDisponibilitaArray = this.form.get('disponibilita') as FormArray;
    this.formProdottiArray = this.form.get('prodotti') as FormArray;

    this.form.get('categoria_id').valueChanges.subscribe((val) => {

      val = parseInt(val);

      this.selectedTrattamento = this.categorieTrattamento ? this.categorieTrattamento.find(item => parseInt(item.id) === parseInt(val)) : null;

      if (this.selectedTrattamento) {

        // mantenimento
        if (!this.form.controls.num_appuntamenti.touched) {
          this.form.controls.num_appuntamenti.setValue(this.selectedTrattamento.numero_trattamenti);
        }

        this.fromDisponibilitaArray.clear();

        this.isCustom = val === 11 || val === 12;

        if (this.selectedTrattamento.periodicita.length) {
          this.selectedTrattamento.periodicita.map((item, index) => {
            this.fromDisponibilitaArray.push(new FormGroup({
              giorno: new FormControl(this.selectedTrattamento.periodicita[index].description),
              orario: new FormControl(''),
            }));
          });
        } else if (val === 12) {
          this.fromDisponibilitaArray.push(new FormGroup({
            giorno: new FormControl(`Ogni ${this.form.value.num_mesi} mesi`),
            orario: new FormControl(''),
          }));
        } else {
          this.fromDisponibilitaArray.push(new FormGroup({
            giorno: new FormControl(''),
            orario: new FormControl(''),
          }));
        }
      }

    });

    this.form.get('num_mesi').valueChanges.subscribe((val) => {

      this.fromDisponibilitaArray.removeAt(0);
      this.fromDisponibilitaArray.push(new FormGroup({
        giorno: new FormControl(`Ogni ${val} mesi`),
        orario: new FormControl(''),
      }));

    });

  }

  ngOnInit() {

    if (this.trattamento) {
      this.categoria = this.trattamento.categoria.categoria;
    }

    if (this.trattamento || this.categoria) {
      this.store.select(this.categoria === 'trattamento' ? getCategorieTrattamento : getCategorieMantenimento).subscribe(records => {
        this.categorieTrattamento = records;
      });
    }

    this.store.select(getTipologieTrattamento).subscribe(tipologie => this.tipologieTrattamento = tipologie);

    if (this.trattamento) {

      this.isCustom = this.trattamento.categoria_id === 11;

      this.form.get('note').setValue(this.trattamento.note);
      this.form.get('categoria_id').setValue(this.trattamento.categoria_id);

      this.formProdottiArray.clear();
      this.fromDisponibilitaArray.clear();

      this.trattamento.disponibilita.map(item => this.fromDisponibilitaArray.push(new FormGroup({
        giorno: new FormControl(item.giorno),
        orario: new FormControl(item.orario),
      })));

      if (this.trattamento.products && this.trattamento.products.length) {
        this.trattamento.products.map(item => this.formProdottiArray.push(new FormControl(item)));
      }

      this.productDescriptions = this.trattamento.products.reduce((acc, item, index) => ({...acc, [index]: item.note}), {});

    }

  }

  handleAddDisponibilita() {
    this.fromDisponibilitaArray.push(
      new FormGroup({
        giorno: new FormControl(''),
        orario: new FormControl(''),
      })
    );
  }

  handleRemoveDisponibilita(index) {
    this.fromDisponibilitaArray.removeAt(index);
  }

  handleAddProdotto(product) {
    this.formProdottiArray.push(new FormControl(product));
  }


  handleRemoveProdotto(index) {
    this.formProdottiArray.removeAt(index);
  }

  handleSubmit() {

    const prodotti =  this.formProdottiArray.value.filter(item => !!item).map((item, index) => ({name: item.name, note: this.productDescriptions[index]}));
    const disponibilita =  this.fromDisponibilitaArray.value.filter(item => item.giorno.trim() !== '');
    const formValue = {...this.form.value, prodotti, disponibilita};

    if (this.trattamento) {

      this.service.update(this.clienteId, this.trattamento.id, formValue)
        .subscribe(({ record }) => {
          this.modal.close(record);
        }, (error) => {
          this.errors = error.error;
        });

    } else {

      this.service.create(this.clienteId, formValue)
        .subscribe(({ record }) => {
          this.modal.close(record);
        }, (error) => {
          this.errors = error.error;
        });

    }

  }

  handleAddPeriodicita() {

    this.fromDisponibilitaArray.push(new FormGroup({
      giorno: new FormControl(''),
      orario: new FormControl(''),
    }));


  }

  handleRemovePeriodicita(i: number) {

    this.fromDisponibilitaArray.removeAt(i);

  }

  handleUpdateNote({ id, value }) {

    this.productDescriptions = {...this.productDescriptions, [id]: value};

  }

}
