import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {BrowserTracing} from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  Sentry.init({
    environment: 'production',
    dsn: 'https://63eebc85d4984c578883ab3662e94eb7@o1045695.ingest.sentry.io/6527763',
    integrations: [
      new BrowserTracing({
        tracingOrigins: [environment.API_ENDPOINT],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
