import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'durata',
  pure: false
})

export class DurataPipe implements PipeTransform {
  transform(value: any): any {
    value = parseFloat(value);
    const hours = value < 1 ? value : Math.floor(value);
    const minutes = value < 1 ? 0 : value % hours;

    if (hours === 0.5) {
      return 'mezz\'ora';
    } else if (hours < 1) {
      return hours * 60 + ' minuti';
    } else {
      let minutesString = '';
      if (minutes === 0.25) {
        minutesString = ' e un quarto';
      } else if (minutes === 0.5) {
        minutesString = ' e mezza';
      } else if (minutes > 0) {
        minutesString = ` e ${minutes * 60}`;
      }
      return `${hours} or${hours > 1 ? 'e' : 'a'}${minutesString}`;
    }

  }
}
