import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from './core/store/store.module';
import {isMenuHidden} from './core/store/ui/selectors';
import {isLogged} from './core/store/auth/selectors';
import {toggleMenu} from './core/store/ui/actions';
import {getUserId} from './core/store/account/selectors';
import * as Sentry from '@sentry/angular';
import {loadRecords as loadSettings} from "./core/store/settings/actions";

@Component({
  selector: 'app-root',
  template: `
    <div [ngClass]="{
            'header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable': isLogged,
            'aside-minimize': isMenuHidden
        }">
      <div [ngClass]="{'d-flex flex-column flex-root': isLogged}">
        <div [ngClass]="{'d-flex flex-row flex-column-fluid page': isLogged}">
          <app-menu [vertical]="false" *ngIf="isLogged"></app-menu>
          <div [ngClass]="{'d-flex flex-column flex-row-fluid wrapper': isLogged}">
            <app-topbar (showCamHandler)="showTricoCam = true" *ngIf="isLogged"></app-topbar>
            <div [ngClass]="{'content': isLogged}">
              <router-outlet (activate)="handleRouteChange()"></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <app-photo-booth (onClose)="showTricoCam = false" *ngIf="showTricoCam"></app-photo-booth>
    </div>
    <app-notifications></app-notifications>
    <app-loader></app-loader>
  `,
})
export class AppComponent {

  account = null;
  isLogged = false;
  isMenuHidden = true;
  showTricoCam = false;

  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {

    this.translateService.setDefaultLang('it');
    this.store.select(isMenuHidden).subscribe(value => this.isMenuHidden = value);
    this.store.select(isLogged).subscribe(value => this.isLogged = value);
    this.store.select(getUserId).subscribe((id) => {
      Sentry.setContext('character', {
        user_id: id
      });
    });
    this.store.dispatch(loadSettings());

  }

  handleRouteChange() {
    if (this.isMenuHidden) {
      this.store.dispatch(toggleMenu({value: true}));
    }
  }

}
