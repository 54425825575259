import {Action, createReducer, on} from '@ngrx/store';
import {loadRecordsSuccess} from './actions';
import {State} from './feature.module';
import {logoutSuccess} from '../auth/actions';

const initialState: State = {
    categorieTrattamento: null,
    tipologieTrattamento: null
};

const featureReduce = createReducer(
    initialState,
    on(loadRecordsSuccess, (state, {type, ...settings}) => settings),
    on(logoutSuccess, () => initialState),
);

export function reducers (state: State | null, action?: Action) {
    return featureReduce(state, action);
}
