import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';

/* Auth */
import {State as AuthState} from './auth';
import {reducer as authReducer} from './auth/reducers';
import {Effects as AuthEffects} from './auth/effects';

/* Account */
import {State as AccountState} from './account';
import {reducer as accountReducer} from './account/reducers';
import {Effects as AccountEffects} from './account/effects';

/* UI */
import {State as UIState} from './ui';
import {reducer as uiReducer} from './ui/reducers';
import {Effects as UIEffects} from './ui/effects';

/* Settings */
import {State as SettingsState} from './settings/feature.module';
import {reducers as settingsReducer} from './settings/reducers';
import {Effects as SettingsEffects} from './settings/effects';

/* Store */
import {Effects as StoreEffects} from './router/effects';

import {FeatureStoreModule as StoreClientiModule} from '../../features/clienti/store/feature-store.module';
import {FeatureStoreModule as StoreProdottiModule} from '../../features/prodotti/store/feature-store.module';
import {FeatureStoreModule as StoreUtentiModule} from '../../features/admin/utenti/store/feature-store.module';
import {FeatureStoreModule as StorePreventiviModule} from '../../features/preventivi/store/feature-store.module';

export interface AppState {
    auth: AuthState;
    account: AccountState;
    ui: UIState;
    settings: SettingsState;
}

@NgModule({
    imports: [
        StoreModule.forRoot({
            ui: uiReducer,
            account: accountReducer,
            auth: authReducer,
            settings: settingsReducer
        },{
            runtimeChecks: {
                strictStateImmutability: true,
                strictStateSerializability: true,
                strictActionImmutability: true,
                strictActionSerializability: true
            }
        }),

        // features modules
        // SettingsModule,
        StoreClientiModule,
        StoreProdottiModule,
        StoreUtentiModule,
        StorePreventiviModule,

        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),

        EffectsModule.forRoot([
            AuthEffects,
            AccountEffects,
            UIEffects,
            StoreEffects,
            SettingsEffects
        ])
    ]
})

export class AppStoreModule {}
