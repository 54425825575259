export const parseHttpResponse = (response): any => ({
	success: response.status === 200,
	errors: response.error.errors,
	message: response.error.message
});

export const convertDT = (datetime) => datetime ? datetime.replace(' ', 'T') : datetime;

export const dateString = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
  return yourDate.toISOString().indexOf('T') !== -1 ? yourDate.toISOString().split('T')[0] : yourDate.toISOString().split(' ')[0];
};

export const timeString = (yourDate) => {
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
  return yourDate.toISOString().indexOf('T') !== -1 ? yourDate.toISOString().split('T')[1].substr(0, 5) : yourDate.toISOString().split(' ')[1].substr(0, 5);
};


export const dateToDatetime = (date: Date = null, time: boolean = true) => {

  if (!date) {
    date = new Date();
    if (date.getMinutes() > 30) {
      date.setMinutes(30);
    } else {
      date.setMinutes(0);
    }
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}` + (time ? ` ${hours}:${minutes}:00` : '');
};

export const isValidDatetime = (date: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2} \d{1,2}:\d{2}:\d{2}$/;
  return regex.test(date);
};
