import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTableComponent} from './components/data-table.component';
import {PaginationComponent} from './components/pagination.component';
import {NotificationComponent} from './components/notification.component';
import {RouterModule} from '@angular/router';
import {MatInputModule} from '@angular/material';
import {DebounceDirective} from './directives/debounce.directive';
import {ActionsDropdownComponent} from './components/actions-dropdown.component';
import {SortPipe} from './pipes/sort.pipe';
import {FileUploadComponent} from './components/file-upload.component';
import {SubareaComponent} from './components/subarea.component';
import {ArrayJoinPipe} from './pipes/arrayJoin.pipe';
import {ForPipe} from './pipes/for.pipe';
import {MessagesComponent} from '../features/assistenza/components/modals/messages.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditorComponent} from './components/editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {FotoItemComponent} from './components/foto-item.component';
import {SearchBoxComponent} from './components/search-box.component';
import {ScalaAlopeciaComponent} from './components/scala-alopecia.component';
import {SchedaTrattamentoModalComponent} from '../features/clienti/components/scheda-trattamento-modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ProductsNameListPipe} from './pipes/products-name-list.pipe';
import {SwitchComponent} from './components/switch.component';
import {DobComponent} from './components/dob.component';
import {DatetimePickerComponent} from './components/datetime-picker.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {DurataPipe} from './pipes/durata.pipe';
import {DateFormatPipe} from './pipes/dateformat.pipe';
import {ProductsSelectorComponent} from './components/products-selector.component';
import {DataTableModule} from '../../../projects/data-table/src/lib/data-table.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {LaravelErrorsComponent} from './components/laravel-errors.component';
import {SelectDateModalComponent} from './components/select-date-modal.component';
import {CalendarModule} from 'angular-calendar';
import {LineeProdottoSelectorComponent} from './components/linee-prodotto-selector.component';
import {GoogleDatePickerComponent} from './components/google-date-picker.component';
import {StatoLabelPipe} from './pipes/statoLabel.pipe';
import {FieldDurataComponent} from './components/field-durata.component';
import {CicloTrattamentoModalComponent} from './components/ciclo-trattamento-modal.component';
import {ModalCalendarComponent} from "./components/modal-calendar.component";

const COMPONENTS = [
  DataTableComponent,
  PaginationComponent,
  NotificationComponent,
  ActionsDropdownComponent,
  FileUploadComponent,
  SubareaComponent,
  DebounceDirective,
  SortPipe,
  ArrayJoinPipe,
  ForPipe,
  MessagesComponent,
  EditorComponent,
  FotoItemComponent,
  SearchBoxComponent,
  ScalaAlopeciaComponent,
  SchedaTrattamentoModalComponent,
  ProductsNameListPipe,
  DurataPipe,
  DobComponent,
  DatetimePickerComponent,
  DateFormatPipe,
  ProductsSelectorComponent,
  SwitchComponent,
  HasPermissionDirective,
  LaravelErrorsComponent,
  SelectDateModalComponent,
  LineeProdottoSelectorComponent,
  GoogleDatePickerComponent,
  StatoLabelPipe,
  FieldDurataComponent,
  CicloTrattamentoModalComponent,
  ModalCalendarComponent,
];

@NgModule({
  providers: [ProductsNameListPipe],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgSelectModule,
    DpDatePickerModule,
    NgbModalModule,
    DataTableModule,
    DragDropModule,
    CalendarModule,
  ],
  entryComponents: [
    MessagesComponent,
    SchedaTrattamentoModalComponent,
    SelectDateModalComponent,
    CicloTrattamentoModalComponent,
  ]
})
export class SharedModule {
}
