
    <form [formGroup]="form"
          class="FotoItem image-input image-input-outline {{ template ? 'FotoItem--' + template : ''}}"
          [ngClass]="{'FotoItem-deleted': form.value.deleted}">
      <div (click)="onImageClick.emit(item)" *ngIf="edit" [ngStyle]="{height: this.height + 'px'}"
           class="FotoItem_Wrapper image-input-wrapper" [style.backgroundImage]="getImage()"></div>
      <a href="{{ item.fullUrl }}" target="_blank" *ngIf="!edit" class="FotoItem_Wrapper image-input-wrapper" [style.backgroundImage]="'url(' + (item.base64 ? item.base64 : item.fullUrl) +')'"></a>
      <div class="input-group input-group-sm" *ngIf="hasDescription">
        <input *ngIf="edit" formControlName="description" type="text" class="form-control" placeholder="Inserisci una descrizione..." (blur)="handleSaveInput(false)">
        <span *ngIf="!edit && form.value.description">{{ item.description }}</span>
      </div>
      <div *ngIf="form.value.deleted || form.value.moved" class="FotoItem_Deleted">
        <div *ngIf="form.value.deleted">
          L'immagine verrà eliminata una volta cliccato su "Salva"<br>
          <button (click)="handleRipristina()" type="button" class="btn btn-sm btn-success mt-2">Ripristina
          </button>
        </div>
        <div *ngIf="form.value.moved">
          L'immagine sarà spostata in questa cartella:
          <select formControlName="cartella_id" class="form-control" (change)="handleSaveInput()">
            <option value="">Seleziona cartella</option>
            <option *ngFor="let cartella of cartelle" [value]="cartella.id" [disabled]="cartella.id === item.cartella_id">{{ cartella.nome }}</option>
          </select>
          <button (click)="handleRipristina()" type="button" class="btn btn-sm btn-success mt-2">Annulla</button>
        </div>
      </div>

      <div class="FotoItem_Buttons" *ngIf="edit && !form.value.deleted && !form.value.moved">
        <button *ngIf="canMove" (click)="handleMove()" class="btn btn-xs btn-primary mr-2">Sposta</button>
        <button (click)="handleDelete()" class="btn btn-xs btn-danger">Elimina</button>
      </div>
    </form>

  