import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from './feature.module';

export const featureSelect = createFeatureSelector<State>('settings');

export const getCategorieTrattamento = createSelector(
    featureSelect,
    (state: State) => !state.categorieTrattamento ? null : state.categorieTrattamento.filter(item => item.categoria === 'trattamento')
);

export const getCategorieMantenimento = createSelector(
    featureSelect,
    (state: State) => !state.categorieTrattamento ? null : state.categorieTrattamento.filter(item => item.categoria === 'mantenimento')
);

export const getTipologieTrattamento = createSelector(
    featureSelect,
    (state: State) => state.tipologieTrattamento
);
