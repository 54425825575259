import {AppState} from '../store.module';
import {createSelector} from '@ngrx/store';
import {State} from '.';

export const selectAccount = (state: AppState) => state.account;

export const getUserName = createSelector(
    selectAccount,
    (account: State) => account.nome ? `${account.nome} ${account.cognome}` : ''
);

export const getShopName = createSelector(
    selectAccount,
    (account: State) => account.shop_name ? account.shop_name : `${account.nome} ${account.cognome}`
);

export const getUserId = createSelector(
    selectAccount,
    (account: State) => account.id
);

export const getPermissions = createSelector(
    selectAccount,
    (account: State) => account.permissions
);

export const getCalendarSettings = createSelector(
    selectAccount,
    (account: State) => account.settings
);

export const getGiorniChiusura = createSelector(
    selectAccount,
    (account: State) => account.settings ? account.settings.days.map((day, index) => !day ? (index === 6 ? 0 : index + 1) : -1).filter(item => item !== -1) : []
);

export const can = createSelector(
    selectAccount,
    (account: State, permission) => account.permissions.indexOf(permission) !== -1
);

export const getUnreadMessages = createSelector(
    selectAccount,
    (account: State) => account.unreadMessages
);

export const getUnreadNotifications = createSelector(
    selectAccount,
    (account: State) => account.unreadNotifications
);

export const getUserData = createSelector(
    selectAccount,
    (account: State) => account
);

export const getSmshosting = createSelector(
    selectAccount,
    (account: State) => account.smshosting
);

export const getSmshostingIsActive = createSelector(
    selectAccount,
    (account: State) => account.smshosting && account.smshosting.status === 'active'
);
export const getGoogleCalendarConnected = createSelector(
    selectAccount,
    (account: State) => account.google_calendar
);

export const hasCustomProductsPermission = createSelector(
  selectAccount,
  (account: State) => account.permissions.indexOf('prodotti.gestione-personalizzata') !== -1
);

export const hasPreventivoProdottiAggiuntiviPermission = createSelector(
  selectAccount,
  (account: State) => account.permissions.indexOf('preventivi.prodotti-aggiuntivi') !== -1
);

export const hasConfermaMantenimento = createSelector(
  getSmshosting,
  (impostazioni) => impostazioni && impostazioni.status === 'active' && impostazioni.sms_conferma_mantenimento_attivo
);

export const getTemporanyFilesCount = createSelector(
  selectAccount,
  (account: State) => account.temporany_files_count
);
