
    <h5><strong>Servizi aggiuntivi</strong></h5>

    <table class="table" [formGroup]="productsForm">
      <thead>
        <tr>
          <th>Descrizione</th>
          <th width="85">Q.tà</th>
          <th width="110">Costo unit.</th>
          <th class="text-right" width="90">Totale</th>
          <th></th>
        </tr>
      </thead>
      <tbody formArrayName="prodotti_aggiuntivi">
        <tr *ngFor="let product of products.controls; let i = index" [formGroupName]="i">
          <td><textarea formControlName="nome" class="form-control" placeholder="Descrizione..." rows="1"></textarea></td>
          <td><input formControlName="qta" class="form-control" type="number" ></td>
          <td><input formControlName="costo" class="form-control" type="number" step="0.01" ></td>
          <td class="text-right pt-6">{{ product.value.costo * product.value.qta | currency: '€' }}</td>
          <td>
            <button class="btn btn-sm btn-secondary" (click)="addProduct()"><i class="fa fa-plus pr-0"></i></button>
            <button class="btn btn-sm btn-danger" (click)="removeProduct(i)"><i class="fa fa-trash pr-0"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  