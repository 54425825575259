

    <div class="wrapper">
      <div class="wrapper_calendar">
        <h3>{{ (viewDate$ | async | dateFormat: "EEEE dd MMMM yyyy") | titlecase }}</h3>

        <mwl-calendar-day-view
          [viewDate]="viewDate$ | async"
          [events]="events"
          [dayStartHour]="8"
          [dayEndHour]="20"
          [hourSegmentHeight]="80"
          [hourSegments]="1"
          [tooltipAppendToBody]="false"
          (hourSegmentClicked)="handleSegmentClick($event)"
        >
        </mwl-calendar-day-view>
      </div>
      <div class="wrapper_form">
        <div class="modal-header">
          <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
            <h4 class="modal-title"><strong>Data appuntamento</strong></h4>
          </div>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (submit)="handleSubmit()">
            <p>Seleziona la data dell'appuntamento:</p>
            <app-datetime-picker formControlName="data"></app-datetime-picker>

            <div *ngIf="note" class="mt-3 mb-3">Note: {{ note }}</div>

            <div *ngIf="pianificaFuturi">
              <label class="checkbox checkbox-success mt-5">
                <input type="checkbox" formControlName="calcola">
                <span></span>Calcola automaticamente le date dei prossimi appuntamenti.<br />
                <small style="display: block; margin-top: 5px;">Ricorda che il calcolo potrebbe coincidere con giorni di chiusura non ancora programmati o festività. Controllale sempre prima di confermarle al cliente.</small>
              </label>
            </div>

            <div class="form-group row mt-3" *ngIf="showConfirm && (hasConfermaMantenimento$ | async)">
              <label class="col-form-label">Stato</label>
              <div class="control-input">
                <div>
                  <select formControlName="stato" class="form-control" required>
                    <option value="scheduled">Appuntamento programmato</option>
                    <option value="to_be_confirmed">In attesa di conferma</option>
                    <option value="confirmed">Appuntamento confermato</option>
                  </select>
                </div>
                <div *ngIf="errors && errors.errors && errors.errors.stato"
                     class="invalid-feedback"
                     role="alert">
                  {{ errors.errors.stato[0] }}
                </div>
              </div>
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
          <button [disabled]="form.invalid" class="btn btn-primary" (click)="handleSubmit()">Conferma</button>
        </div>
      </div>
    </div>

  