import {Component, forwardRef, OnInit} from "@angular/core";
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ProdottoAggiuntivo} from "../models";

@Component({
  selector: 'app-preventivo-prodotti-aggiuntivi',
  styles: [`
    .form-control {
      padding: 5px 10px;
    }
    table tbody tr:not(:last-child) .btn-secondary,
    table tbody tr:last-child .btn-danger {
      display: none;
    }
    table td,
    table th {
      vertical-align: top;
      padding-left: 3px;
      padding-right: 3px;
    }
    table textarea {
      min-height: 41px;
    }
  `],
  template: `
    <h5><strong>Servizi aggiuntivi</strong></h5>

    <table class="table" [formGroup]="productsForm">
      <thead>
        <tr>
          <th>Descrizione</th>
          <th width="85">Q.tà</th>
          <th width="110">Costo unit.</th>
          <th class="text-right" width="90">Totale</th>
          <th></th>
        </tr>
      </thead>
      <tbody formArrayName="prodotti_aggiuntivi">
        <tr *ngFor="let product of products.controls; let i = index" [formGroupName]="i">
          <td><textarea formControlName="nome" class="form-control" placeholder="Descrizione..." rows="1"></textarea></td>
          <td><input formControlName="qta" class="form-control" type="number" ></td>
          <td><input formControlName="costo" class="form-control" type="number" step="0.01" ></td>
          <td class="text-right pt-6">{{ product.value.costo * product.value.qta | currency: '€' }}</td>
          <td>
            <button class="btn btn-sm btn-secondary" (click)="addProduct()"><i class="fa fa-plus pr-0"></i></button>
            <button class="btn btn-sm btn-danger" (click)="removeProduct(i)"><i class="fa fa-trash pr-0"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProdottiAggiuntiviComponent),
      multi: true
    }
  ],
})

export class ProdottiAggiuntiviComponent implements ControlValueAccessor, OnInit {

  productsForm: FormGroup;
  productsArray: FormArray;

  // Per implementare ControlValueAccessor
  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.productsForm = this.fb.group({
      prodotti_aggiuntivi: this.fb.array([])
    });

    this.productsArray = this.productsForm.get('prodotti_aggiuntivi') as FormArray;

    // Quando il formArray cambia, notifica il componente padre
    this.productsArray.valueChanges.subscribe(value => {
      this.onChange(value);
    });

    this.addProduct({
      nome: '',
      qta: null,
      costo: null
    });

  }

  writeValue(value: ProdottoAggiuntivo[]): void {
    if (value && value.length) {
      // Resetta l'array prima di aggiungere nuovi elementi
      while (this.productsArray.length) {
        this.productsArray.removeAt(0);
      }

      // Aggiungi tutti i prodotti ricevuti
      value.forEach(product => {
        this.addProduct(product);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.productsForm.disable();
    } else {
      this.productsForm.enable();
    }
  }

  // Metodi per manipolare i prodotti
  addProduct(product: ProdottoAggiuntivo = { nome: '', costo: 0, qta: 1 }): void {
    const productForm = this.fb.group({
      nome: [product.nome],
      costo: [product.costo],
      qta: [product.qta]
    });

    this.productsArray.push(productForm);
    this.onChange(this.productsArray.value);
  }

  removeProduct(index: number): void {
    this.productsArray.removeAt(index);
    this.onChange(this.productsArray.value);
  }

  get products(): FormArray {
    return this.productsArray;
  }


}
