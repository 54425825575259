import {Injectable} from '@angular/core';
import {ApiClient} from '../../core/services/api.service';
import {Observable} from 'rxjs';
import {Event} from '../../models/Event';
import {DashboardResponseInterface} from "./interfaces/DashboardResponseInterface";
import {TemporaryFile} from "../../models/TemporaryFile";

@Injectable({
  providedIn: 'root',
})

export class FeatureService {

  constructor(
    private http: ApiClient
  ) {
  }

  calendar({date, type, escludiTrattamento = null}): Observable<Event[]> {

    // @ts-ignore
    return this.http.get(`events?date=${date}&type=${type}${escludiTrattamento ? '&escludiTrattamento=' + escludiTrattamento : ''}`);

  }

  searchClient({query}): any {

    return this.http.get(`events/search?search=${query}`);

  }

  createEvent(formData) {

    return this.http.post(`events`, formData);

  }

  updateEvent(id, formData) {

    formData = Object.assign({}, formData, {'_method': 'PUT'})
    return this.http.post(`events/${id}`, formData);

  }

  deleteEvent(id, formData) {

    formData = Object.assign({}, formData, {'_method': 'DELETE'})
    return this.http.post(`events/${id}`, formData);

  }

  dashboard(): Observable<DashboardResponseInterface> {

    // @ts-ignore
    return this.http.get('dashboard');

  }

  checkupNonPianificati() {

    return this.http.get('checkup/pending');

  }

  nuovoCheckup(data) {

    return this.http.post('checkup/pending', data);


  }

  getCalendarUrl() {

    return this.http.get('account/calendar-url');

  }

  getGoogleConnectionUrl() {

    return this.http.getEndpoint('calendar/connect');

  }

  getGoogleDisconnectionUrl() {

    return this.http.getEndpoint('calendar/disconnect');

  }

  getTrattamentiAttivi(categoria) {

    return this.http.get(`${categoria}`);

  }

  getMantenimentiTerminati() {

    return this.http.get('mantenimenti/terminati');

  }

  sendConfirmRequest({ type, id }) {

    return this.http.post('events/send-confirm-request', { type, id })

  }

  fotoNonCaricate(): Observable<TemporaryFile[]> {

    // @ts-ignore
    return this.http.get('user/foto-non-caricate');

  }

  deleteFtoNonCaricate(ids): Observable<any> {

    const data: {  ids: number[], _method: string } = {
      ids,
      _method: 'delete'
    };

    // @ts-ignore
    return this.http.post('user/foto-non-caricate', data);

  }

}
