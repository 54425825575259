/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./notification.component";
import * as i3 from "../../core/services/notification.service";
var styles_NotificationComponent = [".Notifications[_ngcontent-%COMP%] {\n            position: fixed;\n            left: 50%;\n            top: 0;\n            z-index: 9999;\n            transform: translateX(-50%);\n        }\n\n        .Notifications__Item[_ngcontent-%COMP%] {\n            position: relative;\n            padding: 20px 50px 20px 30px;\n            border-radius: 5px;\n            background: rgba(79, 197, 91, 0.3);\n            margin-top: 20px;\n            color: #fff;\n        }\n\n        .Notifications__ItemClose[_ngcontent-%COMP%] {\n\n            position: absolute;\n            top: 20px;\n            right: 10px;\n            background: transparent;\n            border: none;\n            color: #fff;\n\n        }\n\n        .Notifications--alert[_ngcontent-%COMP%] {\n            background: rgba(214, 82, 33, 1);\n        }\n\n        .Notifications--success[_ngcontent-%COMP%] {\n            background: #1b6422;\n        }\n\n        .Notifications[_ngcontent-%COMP%]   p[_ngcontent-%COMP%] {\n            margin: 0;\n        }"];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "Notifications__Item"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "Notifications--alert": 0, "Notifications--success": 1 }), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "Notifications__ItemClose"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "mdi mdi-close"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Notifications__Item"; var currVal_1 = _ck(_v, 3, 0, !_co.service.getItem().success, _co.service.getItem().success); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.service.getItem().message; _ck(_v, 4, 0, currVal_2); }); }
export function View_NotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "Notifications"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NotificationComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.service.item)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 49152, null, 0, i2.NotificationComponent, [i3.NotificationService], null, null)], null, null); }
var NotificationComponentNgFactory = i0.ɵccf("app-notifications", i2.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
