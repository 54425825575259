import {Injectable} from '@angular/core';
import {ApiClient} from '../../../../core/services/api.service';
import {Observable} from "rxjs";
import {TempFoto} from "../../../../models/temp-foto";
import {Cartella} from "../../../../models/Cartella";

@Injectable({
  providedIn: 'root',
})

export class FotoService {

  private apiSegment = 'clienti';

  constructor(
    private http: ApiClient,
  ) {
  }

  all(clienteId, guida = false): Observable<Cartella[]> {

    // @ts-ignore
    return this.http.get(`${this.apiSegment}/${clienteId}/foto?guida=${guida}`);

  }

  cartelle(clienteId: number) {

    return this.http.get(`${this.apiSegment}/${clienteId}/cartelle`);

  }

  item(clienteId: number, cartellaId: number): Observable<Cartella> {

    // @ts-ignore
    return this.http.get(`${this.apiSegment}/${clienteId}/cartelle/${cartellaId}`);

  }

  create(clienteId: number, data: FormData | object): Observable<{ message: string, record: Cartella }> {

    // @ts-ignore
    return this.http.post(`${this.apiSegment}/${clienteId}/foto`, data);

  }

  update(clienteId: number, cartellaId: number, data: FormData | object): Observable<{ message: string, record: Cartella }> {

    if (data instanceof FormData) {
      data.append('_method', 'PUT');
    } else {
      data = {...data, _method: 'PUT'};
    }

    // @ts-ignore
    return this.http.post(`${this.apiSegment}/${clienteId}/cartelle/${cartellaId}`, data);

  }

  delete(clienteId: number, cartellaId: number) {

    return this.http.delete(`${this.apiSegment}/${clienteId}/foto/${cartellaId}`);

  }

  order(clienteId, ids) {

    return this.http.put(`${this.apiSegment}/${clienteId}/cartelle/ordine`, {
      order: ids
    });

  }

  print(clienteId, id1, id2) {

    return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/print/${id1}_${id2}`);

  }

  download(clienteId, id) {
    return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/${id}/download`);
  }

  postTemp(data: { base64: string }): Observable<TempFoto> {

    // @ts-ignore
    return this.http.post(`user/temp-foto`, data);

  }

  updateTemp(id, data) {

    return this.http.post(`user/temp-foto/${id}`, {
      _method: 'put',
      ...data
    });

  }

  deleteTemp(id) {

    return this.http.post(`user/temp-foto/${id}`, {
      _method: 'delete'
    });

  }

}
