import { ApiClient } from '../../core/services/api.service';
import { FeatureStore } from './feature.store';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
import * as i2 from "./feature.store";
export class FeatureService {
    constructor(http, store) {
        this.http = http;
        this.store = store;
        this.apiSegment = 'clienti';
    }
    all(params = null) {
        const queryString = params ? this.http.getQueryStringFromObject(params) : '';
        return this.http.get(`${this.apiSegment}?${queryString}`);
    }
    create(data) {
        return this.http.post(`${this.apiSegment}`, data);
    }
    item(id) {
        return this.http.get(`${this.apiSegment}/${id}`);
    }
    anagrafica(id) {
        return this.http.get(`${this.apiSegment}/${id}/anagrafica`);
    }
    scheda(id) {
        return this.http.get(`${this.apiSegment}/${id}/riepilogo`);
    }
    update(id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`${this.apiSegment}/${id}`, data);
    }
    /* @deprecated */
    // updateActive(id, active) {
    //
    //     return this.http.put(`${this.apiSegment}/${id}/active`, {active});
    //
    // }
    patch(id, data) {
        data = Object.assign({}, data, { _method: 'PATCH' });
        return this.http.post(`${this.apiSegment}/${id}`, data);
    }
    updateOperatore(id, data) {
        return this.http.put(`${this.apiSegment}/${id}/operatore`, data);
    }
    avatar(id, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { '_method': 'PUT' });
        }
        return this.http.post(`${this.apiSegment}/${id}/avatar`, data);
    }
    updatePrivacyFile(id, file) {
        const formData = new FormData();
        formData.append('privacy_file', file);
        formData.append('_method', 'PUT');
        return this.http.post(`${this.apiSegment}/${id}/privacy`, formData);
    }
    delete(id) {
        return this.http.delete(`${this.apiSegment}/${id}`);
    }
    checkup(id) {
        return this.http.get(`${this.apiSegment}/${id}/checkup`);
    }
    archivioAppuntamenti(id) {
        return this.http.get(`${this.apiSegment}/${id}/archivio-appuntamenti`);
    }
    stampaCheckup(id) {
        return this.http.getEndpoint(`${this.apiSegment}/${id}/checkup/pdf`);
    }
    storeCheckup(id, data) {
        return this.http.post(`${this.apiSegment}/${id}/checkup`, data);
    }
    updateFotoCheckup(id, data) {
        return this.http.post(`${this.apiSegment}/${id}/checkup/foto`, data);
    }
    trattamento(id, data) {
        return this.http.post(`${this.apiSegment}/${id}/trattamento`, data);
    }
    trattamentoItems(id, data) {
        return this.http.get(`${this.apiSegment}/${id}/trattamento`, data);
    }
    pianificaTrattamento(id, data) {
        return this.http.post(`${this.apiSegment}/${id}/trattamento/pianificazione`, data);
    }
    trattamentoItemDetails(clienteId, trattamentoItem, data) {
        return this.http.post(`${this.apiSegment}/${clienteId}/trattamento/${trattamentoItem}/details`, data);
    }
    downloadPdfPrivacy(clienteId) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/privacy`);
    }
    foto(clienteId) {
        return this.http.get(`${this.apiSegment}/${clienteId}/foto`);
    }
    caricaCartella(clienteId, data) {
        return this.http.post(`${this.apiSegment}/${clienteId}/foto`, data);
    }
    updateCartella(clienteId, cartellaId, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        return this.http.post(`${this.apiSegment}/${clienteId}/cartelle/${cartellaId}`, data);
    }
    deleteCartella(clienteId, id) {
        return this.http.delete(`${this.apiSegment}/${clienteId}/foto/${id}`);
    }
    getSchedaUrl(clienteId) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/scheda`);
    }
    sendWelcomeSms(clienteId) {
        return this.http.post(`${this.apiSegment}/${clienteId}/sendWelcomeSms`, {});
    }
}
FeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.ɵɵinject(i1.ApiClient), i0.ɵɵinject(i2.FeatureStore)); }, token: FeatureService, providedIn: "root" });
