import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {convertDT, dateToDatetime} from '../helpers';
import {FeatureService} from '../../features/dashboard/feature.service';
import {BehaviorSubject} from 'rxjs';
import {CalendarEventTitleFormatter} from 'angular-calendar';
import {CustomEventTitleFormatterProvider} from '../providers/custom-event-title-formatter.provider';

@Component({
  selector: 'app-modal-calendar',
  providers: [{
    provide: CalendarEventTitleFormatter,
    useClass: CustomEventTitleFormatterProvider
  }],
  template: `
    <mwl-calendar-day-view
      [viewDate]="date"
      [events]="this.events$ | async"
      [dayStartHour]="8"
      [dayEndHour]="20"
      [hourSegmentHeight]="80"
      [hourSegments]="1"
      [tooltipAppendToBody]="false"
      (hourSegmentClicked)="onSegmentClick.emit($event)"
    ></mwl-calendar-day-view>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ModalCalendarComponent implements OnChanges {

  @Input() date: Date = null;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSegmentClick: EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
  }> = new EventEmitter();
  events$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private service: FeatureService) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.date.currentValue && changes.date.currentValue !== changes.date.previousValue) {
      this.getEvents();
    }
  }

  getEvents() {

    this.service.calendar({ date: dateToDatetime(this.date, false), type: 'day' }).subscribe((events) => {
      this.events$.next(events.map(item => ({
        id: item.id,
        draggable: false,
        allDay: item.type === 'chiusura',
        title: `${item.description}`,
        start: new Date(convertDT(item.data)),
        end: item.type === 'chiusura' ? null : new Date(convertDT(item.dataFine)),
        cssClass: `event-${item.type} event-stato-${item.stato}`,
        meta: {
          cliente: item.cliente,
          descrizione: item.description,
          note: item.note,
          stato: item.stato,
          durata: item.durata,
        }
      })));
    });

  }

}
