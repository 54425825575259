

    <div class="modal-header">
      <h4 class="modal-title">Preventivo</h4>
    </div>
    <div class="modal-body">

      <app-laravel-errors [errors]="errors$ | async"></app-laravel-errors>

      <form [formGroup]="form">


        <div class="form-group row" *ngIf="form.value.in_rubrica">
          <label class="col-form-label">Cliente</label>
          <div class="control-input">
            <ng-select
              [placeholder]="'Seleziona cliente'"
              [items]="clienti$ | async"
              formControlName="cliente_id"
              bindLabel="fullName"
              bindValue="id"
              [closeOnSelect]="true"
            >
              <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                <div class="ng-option disabled">
                  <button class="btn btn-primary btn-sm" (click)="handleNewCliente(searchTerm)">Usa cliente non in
                    rubrica: "{{ searchTerm }}"
                  </button>
                </div>
              </ng-template>
            </ng-select>
            <span *ngIf="errors && errors.errors && errors.errors.cliente_id" class="invalid-feedback"
                  role="alert">{{ errors.errors.cliente_id[0] }}</span>
          </div>
        </div>

        <div class="form-group row" *ngIf="!form.value.in_rubrica">
          <label class="col-form-label">Cliente</label>
          <div class="control-input">
            <div class="name-input-container">
              <input autocomplete="off" class="form-control" type="text" formControlName="nome_cliente"
                     name="nome_cliente"
                     [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.nome_cliente }" required>

              <!--<button (click)="handleResetName()" class="btn btn-clean p-1"><i class="mdi mdi-close"></i></button>-->
            </div>
            <span *ngIf="errors && errors.errors && errors.errors.nome_cliente" class="invalid-feedback" role="alert">
                            {{ errors.errors.nome_cliente[0] }}
                        </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label">Note</label>
          <div class="control-input">
          <textarea class="form-control" type="text" formControlName="note"
                    [ngClass]="{'is-invalid': errors && errors.errors && errors.errors.note }"></textarea>
            <span *ngIf="errors && errors.errors && errors.errors.note" class="invalid-feedback"
                  role="alert">{{ errors.errors.note[0] }}</span>
          </div>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th>Trattamento</th>
            <th width="85">Q.tà</th>
            <th width="110">Costo unit.</th>
            <th class="text-right" width="90">Totale</th>
          </tr>
          </thead>
          <tbody formArrayName="trattamento">
          <tr *ngFor="let _ of formTrattamento.controls; index as i">
            <ng-container [formGroupName]="i">
              <td>
                <div class="mt-3">{{ _.get('name').value }}</div>
              </td>
              <td><input class="form-control" type="number" placeholder="0" formControlName="qta"/></td>
              <td><input class="form-control"
                         [ngClass]="{'is-invalid': errors && errors.errors && errors.errors['trattamento.' + i] }"
                         type="number" step="0.01" placeholder="0,00" formControlName="costo"/></td>
              <td class="text-right">
                <div class="mt-3">{{ _.get('qta').value * _.get('costo').value | currency: '€' }}</div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </table>

        <hr>

        <h5><strong>Elenco prodotti</strong></h5>

        <app-products-selector filtro="ordine" [selectedProducts]="[]" [note]="false"
                               (onAdd)="handleAddProduct($event)"></app-products-selector>

        <ng-container>
          <table class="table" *ngIf="formProdotti.value && formProdotti.value.length">
            <thead>
            <tr>
              <th>Prodotto</th>
              <th class="text-center">Omaggio</th>
              <th class="text-right" width="130">Costo</th>
            </tr>
            </thead>
            <tbody formArrayName="prodotti">
            <tr *ngFor="let _ of formProdotti.controls; index as i">
              <ng-container [formGroupName]="i">
                <td>
                  <div class="d-flex align-items-center mr-3">
                    <button (click)="handleDeleteProduct(i)" class="btn-danger btn btn-sm btn-icon mr-3"
                            style="width: 40px"><i
                      class="flaticon2-trash"></i></button>
                    {{ _.get('name').value }}
                  </div>
                </td>
                <td class="text-center">
                  <input type="checkbox" style="margin-top: 15px" formControlName="omaggio">
                </td>
                <td>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">€</div>
                    </div>
                    <input type="number" step="0.01" class="form-control" style="width: 50px" formControlName="costo">
                  </div>
                </td>
              </ng-container>
            </tr>
            </tbody>
          </table>

        </ng-container>

        <hr>

        <ng-container *ngIf="showProdottiAggiuntiviPermission$ | async">
          <app-preventivo-prodotti-aggiuntivi formControlName="prodotti_aggiuntivi"></app-preventivo-prodotti-aggiuntivi>
          <hr>
        </ng-container>

        <table class="table" style="margin-top: 30px">
          <thead>
          <tr>
            <th>Promozioni</th>
            <th class="text-right" width="130">Importo</th>
          </tr>
          </thead>
          <tbody formGroupName="promozioni">
          <tr>
            <td>Sconto trattamento</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_trattamento">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sconto prodotti</td>
            <td>
              <div class="input-group mb-2">
                <input type="text" class="form-control" style="width: 50px" formControlName="sconto_prodotti">
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </td>
          </tr>
          <!--
          <tr>
            <td>Omaggio prodotto</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="prodotto">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          -->
          <tr>
            <td>Omaggio ultimo trattamento</td>
            <td>
              <label class="checkbox">
                <input type="checkbox" formControlName="trattamento">
                <span></span>
                Si
              </label>
            </td>
          </tr>
          </tbody>
        </table>

        <hr style="margin-top: 50px;">

        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale trattamento:</div>
          <div>{{ getTotaleTrattamento() | currency: '€' }}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale prodotti:</div>
          <div>{{ getTotaleProdotti() | currency: '€' }}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale servizi aggiuntivi:</div>
          <div>{{ getTotaleProdottiAggiuntivi() | currency: '€' }}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto trattamento:</div>
          <div>-{{ getScontoTrattamento() | currency: '€' }}</div>
        </div>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Sconto prodotti:</div>
          <div>-{{ getScontoProdotti() | currency: '€' }}</div>
        </div>
        <hr>
        <div class="font-size-h6 d-flex justify-content-between">
          <div>Totale:</div>
          <div>{{ getTotaleProdotti() + getTotaleTrattamento() + getTotaleProdottiAggiuntivi() - getTotalePromo() | currency: '€' }}</div>
        </div>

      </form>

    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" (click)="modal.dismiss()" class="btn btn-light">Annulla</button>
      <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="handleSubmit()">Salva</button>
    </div>

  