import {Action, createReducer, on} from '@ngrx/store';
import {
  clearAccountData,
  decreaseUnreadMessages,
  getAccountSuccess,
  setAllReadNotification,
  setReadNotification,
  setUnreadMessages,
  updateSettingsSuccess
} from './actions';
import {State} from '.';
import {logoutSuccess} from '../auth/actions';

const initialState: State = {
    id: null,
    nome: '',
    cognome: '',
    email: '',
    phone: '',
    roles: [],
    permissions: [],
    settings: null,
    unreadMessages: 0,
    unreadNotifications: 0,
    smshosting: null,
    shop_name: null,
    has_wa: false,
    temporany_files_count: 0,
};

const accountReducer = createReducer(
    initialState,
    on(getAccountSuccess, (state, action) => ({...state, ...action.account})),
    on(clearAccountData, (state, action) => ({...initialState})),
    on(updateSettingsSuccess, (state, action) => ({...state})),
    on(decreaseUnreadMessages, (state, action ) => ({...state, unreadMessages: state.unreadMessages - action.number })),
    on(setUnreadMessages, (state, action ) => ({...state, unreadMessages: action.number })),
    on(logoutSuccess, () => initialState),
    on(setReadNotification, (state, action) => ({...state, unreadNotifications: state.unreadNotifications - 1})),
    on(setAllReadNotification, (state, action) => ({...state, unreadNotifications: 0})),
);

export function reducer (state: State | null, action?: Action) {
    return accountReducer(state, action);
}
