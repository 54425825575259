import { ApiClient } from '../../../../core/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/api.service";
export class FotoService {
    constructor(http) {
        this.http = http;
        this.apiSegment = 'clienti';
    }
    all(clienteId, guida = false) {
        // @ts-ignore
        return this.http.get(`${this.apiSegment}/${clienteId}/foto?guida=${guida}`);
    }
    cartelle(clienteId) {
        return this.http.get(`${this.apiSegment}/${clienteId}/cartelle`);
    }
    item(clienteId, cartellaId) {
        // @ts-ignore
        return this.http.get(`${this.apiSegment}/${clienteId}/cartelle/${cartellaId}`);
    }
    create(clienteId, data) {
        // @ts-ignore
        return this.http.post(`${this.apiSegment}/${clienteId}/foto`, data);
    }
    update(clienteId, cartellaId, data) {
        if (data instanceof FormData) {
            data.append('_method', 'PUT');
        }
        else {
            data = Object.assign({}, data, { _method: 'PUT' });
        }
        // @ts-ignore
        return this.http.post(`${this.apiSegment}/${clienteId}/cartelle/${cartellaId}`, data);
    }
    delete(clienteId, cartellaId) {
        return this.http.delete(`${this.apiSegment}/${clienteId}/foto/${cartellaId}`);
    }
    order(clienteId, ids) {
        return this.http.put(`${this.apiSegment}/${clienteId}/cartelle/ordine`, {
            order: ids
        });
    }
    print(clienteId, id1, id2) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/print/${id1}_${id2}`);
    }
    download(clienteId, id) {
        return this.http.getEndpoint(`${this.apiSegment}/${clienteId}/foto/${id}/download`);
    }
    postTemp(data) {
        // @ts-ignore
        return this.http.post(`user/temp-foto`, data);
    }
    updateTemp(id, data) {
        return this.http.post(`user/temp-foto/${id}`, Object.assign({ _method: 'put' }, data));
    }
    deleteTemp(id) {
        return this.http.post(`user/temp-foto/${id}`, {
            _method: 'delete'
        });
    }
}
FotoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FotoService_Factory() { return new FotoService(i0.ɵɵinject(i1.ApiClient)); }, token: FotoService, providedIn: "root" });
