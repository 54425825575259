// product-list.component.ts
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

interface Product {
  id?: number;
  name: string;
  price: number;
  quantity: number;
}

@Component({
  selector: 'app-field-durata',
  styles: [`
    .field-durata {
      border: 1px solid #aaaaaa;
      width: 80px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
    }

    .field-durata > div {
      height: auto;
    }

    .field-durata .select-container {
      width: 25px;
      text-align: center;
      position: relative;
      padding: 5px 0;
    }

    .field-durata-small {
      padding: 5px 0;
      width: 80px;
      position: relative;
    }

    .field-durata select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .field-durata select._hours{
      text-align: right;
    }
  `],
  template: `
    <div [formGroup]="form">

      <select formControlName="durata" class="form-control">
        <option value="0.5">30 minuti</option>
        <option value="0.75">45 minuti</option>
        <option value="1">1 ora</option>
        <option value="1.5">1 ora e mezza</option>
        <option value="-1">Durata personalizzata</option>
      </select>

      <div class="d-flex align-items-center mt-2" *ngIf="form.value.durata < 0 || form.value.durata > 2" style="width: 100%">
        <div class="mr-2">Indica la durata: </div>
        <div class="field-durata">
          <div class="select-container _hours mr-1" style="width: 20px">

            {{ form.value.hours }}

            <select formControlName="hours">
              <option disabled>Ore:</option>
              <option [value]="2">2</option>
              <option [value]="3">3</option>
              <option [value]="4">4</option>
            </select>
          </div>
          <div style="padding-top: 5px">:</div>
          <div class="select-container ml-1">

            {{ showMinutes(form.value.minutes) }}

            <select formControlName="minutes">
              <option disabled>Minuti:</option>
              <option [value]="0">00</option>
              <option [value]="0.25">15</option>
              <option [value]="0.5">30</option>
              <option [value]="0.75">45</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldDurataComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldDurataComponent implements ControlValueAccessor, OnInit {

  form: FormGroup;
  disabled = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      durata: this.fb.control(1),
      hours: this.fb.control(2),
      minutes: this.fb.control(0),
    });

    this.form.valueChanges.subscribe(({ durata, hours, minutes }) => {

      if (durata < 0 || durata >= 2) {
        durata = parseInt(hours) + parseFloat(minutes);
      }

      this.onChange(durata);

    });
  }

  writeValue(durata: number): void {

    if (durata) {

      let hours = 2,
          minutes = 0;

      if (durata >= 2) {

        hours = Math.floor(durata);
        minutes = durata % hours;
        durata = -1;

      }

      this.form.patchValue({
        durata,
        hours,
        minutes
      });
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  _parseInt = (value) => {
    return parseInt(value);
  }

  showMinutes(minutes: number): string {
    minutes = 60 * minutes;
    return `0${minutes}`.substr(-2);
  }

}
