export enum EventStatus {
  scheduled = 'Programmato',
  to_be_confirmed = 'In attesa di conferma',
  confirmed = 'Confermato',
  change_request = 'Richiesto spostamento',
  expired = 'Richiessta conferma scaduta'
}

export interface Event {
  id: number;
  type: 'event' | 'trattamento' | 'mantenimento' | 'checkup' | 'chiusura';
  beauty_type: 'appuntamento generico' | 'note' | 'trattamento' | 'mantenimento' | 'checkup';
  trattamento_id: number;
  description: string;
  data: string;
  dataFine: string;
  cliente: string;
  cliente_id: number;
  stato: 'scheduled' | 'to_be_confirmed' | 'confirmed' | 'change_request' | 'expired';
  note?: any;
  durata: string;
  in_rubrica: boolean;
  note_cliente?: any;
  confirm_request_sent_at?: string;
}
