import {Component} from "@angular/core";
import {NotificationService} from '../../core/services/notification.service';

@Component({
    selector: 'app-notifications',
    styles: [`
        .Notifications {
            position: fixed;
            left: 50%;
            top: 0;
            z-index: 9999;
            transform: translateX(-50%);
        }

        .Notifications__Item {
            position: relative;
            padding: 20px 50px 20px 30px;
            border-radius: 5px;
            background: rgba(79, 197, 91, 0.3);
            margin-top: 20px;
            color: #fff;
        }

        .Notifications__ItemClose {

            position: absolute;
            top: 20px;
            right: 10px;
            background: transparent;
            border: none;
            color: #fff;

        }

        .Notifications--alert {
            background: rgba(214, 82, 33, 1);
        }

        .Notifications--success {
            background: #1b6422;
        }

        .Notifications p {
            margin: 0;
        }
    `],
    template: `
        <div class="Notifications">
            <div class="Notifications__Item" *ngIf="service.item | async" [ngClass]="{'Notifications--alert': !service.getItem().success, 'Notifications--success': service.getItem().success}">
                {{ service.getItem().message }}
                <button class="Notifications__ItemClose" (click)="handleClose()"><i class="mdi mdi-close"></i></button>
            </div>
        </div>
    `
})

export class NotificationComponent {

    constructor(public service: NotificationService) {}

    handleClose() {

        this.service.hide()

    }

}
